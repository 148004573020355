 
<template>
    <b-row>
      <b-col>
        <b-card>
          <app-timeline>
            <app-timeline-item :title="$t('OnlineAppointmentsOnlineConsultations')" variant="success"
              icon="AwardIcon" />
            <app-timeline-item :title="$t('FollowAppointmentsConsultations')" variant="info" icon="UserIcon" />

            <app-timeline-item :title="$t('SurgeryRequest')" icon="InfoIcon" variant="warning" />

            <app-timeline-item :title="$t('DoctorsVisitsDestination')" />
            <app-timeline-item :title="$t('PatientsHistoryMedicalService')" variant="info" icon="ClockIcon" />

            <app-timeline-item :title="$t('TravelArrangementsPatients')" variant="warning" />
            <app-timeline-item :title="$t('TranslationServices')" variant="info" />
            <app-timeline-item :title="$t('MedicalPackagesSurgeries')" />
            <app-timeline-item :title="$t('NewslettersHospitalDoctor')" variant="secondary" icon="GridIcon" />
          </app-timeline>
        </b-card>

      </b-col>
      <b-col>
        
<!--        :img-src="require('@/assets/images/patientserv.png')"
          style="width:500px; height: 400px;"
          img-alt="Card image cap"-->
           <b-img style="" src="@/assets/images/patientserv.png" /> 
        
      </b-col>
    </b-row>


</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BRow,
} from "bootstrap-vue"

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BImg,
    BRow,
  },
}
</script>
 